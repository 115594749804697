
import ApiService from "@/core/services/ApiService";
import {Actions} from "@/store/enums/StoreEnums";
import {defineComponent, computed, ref, onBeforeMount, onMounted} from "vue";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";
import type {ScoreSystemType} from "@/types/ScoreSystemType";
import {useI18n} from "vue-i18n";

export default defineComponent({
  name: "leaderboard-component",
  data() {
    return {
       vpn: {
        ip: "",
        online: false
        }
    }
    },
    components: {

    },
    setup() {
      const store = useStore();
      const route = useRoute();
      const router = useRouter();
      const { t } = useI18n();
      const id = ref(2);
      const scoreboard = computed(() => {
         return store.getters.getCurrentScoreSystem;
      });

      const user = computed(() => {
        return store.getters.currentUser;
      });
      const currentLanguage = computed(() => store.getters.getCurrentLanguage || localStorage.getItem('currentLanguage'));

      onMounted( async () =>{
        try{
          await store.dispatch(Actions.GET_SCORE_SYSTEM, id.value);
        }catch (e) {
          console.log(e)
        }
      });

      const scoreLevel = (value: number) => {
        let level = Math.floor(value / 1000);
        let xp = value % 1000;

        return {
          level: level < 1 ? 0 : level,
          xp: xp
        };
      }
      const userPatent = (score: number) => {
        let patent = {
          name: '',
          icon: ''
        }
        if(score){
          if(score < 2000){
            patent.name = t(`${currentLanguage.value}.patents.recruit`);
            patent.icon = 'recruta.png';
          }else if(score >= 2000 && score < 4000){
            patent.icon = 'soldado.png';
            patent.name  = t(`${currentLanguage.value}.patents.soldier`);
          }else if(score >= 4000 && score < 6000){
            patent.icon = 'cabo.png';
            patent.name  = t(`${currentLanguage.value}.patents.cable`);
          }else if(score >= 6000 && score < 8000){
            patent.icon = '3-sargento.png';
            patent.name  = t(`${currentLanguage.value}.patents.3rd_sergeant`);
          }else if(score >= 8000 && score < 10000){
            patent.icon = '2-sargento.png';
            patent.name  = t(`${currentLanguage.value}.patents.2rd_sergeant`);
          }else if(score >= 10000 && score < 12000){
            patent.icon = '1-sargento.png';
            patent.name  = t(`${currentLanguage.value}.patents.1rd_sergeant`);
          }else if(score >= 12000 && score < 14000){
            patent.icon = 'subtenente.png';
            patent.name  = t(`${currentLanguage.value}.patents.warrant_officer`);
          }else if(score >= 14000 && score < 16000){
            patent.icon = 'aspirante.png';
            patent.name  = t(`${currentLanguage.value}.patents.aspirant`);
          }else if(score >= 16000 && score < 18000){
            patent.icon = '2-tenente.png';
            patent.name  = t(`${currentLanguage.value}.patents.2nd_lieutenant`);
          }else if(score >= 18000 && score < 20000){
            patent.icon = '1-tenente.png';
            patent.name  = t(`${currentLanguage.value}.patents.1nd_lieutenant`);
          }else if(score >= 20000 && score < 22000){
            patent.icon = 'capitao.png';
            patent.name  = t(`${currentLanguage.value}.patents.captain`);
          }else if(score >= 22000 && score < 24000){
            patent.icon = 'major.png';
            patent.name  = t(`${currentLanguage.value}.patents.major`);
          }else if(score >= 24000 && score < 26000){
            patent.icon = 'tenente-coronel.png';
            patent.name  = t(`${currentLanguage.value}.patents.lieutenant_colonel`);
          }else if(score >= 26000 && score < 28000){
            patent.icon = 'coronel.png';
            patent.name  = t(`${currentLanguage.value}.patents.colonel`);
          }else if(score >= 28000 && score < 30000){
            patent.icon = 'general-de-ataque.png';
            patent.name  = t(`${currentLanguage.value}.patents.attack_general`);
          }else if(score >= 30000 && score < 32000){
            patent.icon = 'general-de-furtividade.png';
            patent.name  = t(`${currentLanguage.value}.patents.stealth_general`);
          }else if(score >= 32000 && score < 34000){
            patent.icon = 'general-de-inteligencia.png';
            patent.name  = t(`${currentLanguage.value}.patents.general_of_intelligence`);
          }else if(score >= 34000){
            patent.icon = 'marechal.png';
            patent.name  = t(`${currentLanguage.value}.patents.marshal`);
          }else{
            patent.icon = 'recruta.png';
            patent.name  = t(`${currentLanguage.value}.patents.recruit`);
          }
          return patent;
        }else{
          return {
            name: t(`${currentLanguage.value}.patents.recruit`),
            icon: 'recruta.png'
          }
        }
      }
      return {
        user,
        scoreboard,
        scoreLevel,
        currentLanguage,
        userPatent

      };
    },
});
